import React, { useState, useEffect } from 'react';
import { message, Button, Pagination, Spin } from 'antd';
import { useAuth } from '../../context/AuthContext';
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import '../Table.css';
import moment from 'moment-timezone';

const PaymentsPage = () => {
    const [payments, setPayments] = useState([]);
    const { makeAuthenticatedRequest } = useAuth();
    const [, setBayiNames] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchReceipts = async () => {
            setLoading(true); // Veriler yüklenirken loading durumunu başlat
            try {
                const response = await makeAuthenticatedRequest('/param/receipts', 'GET');
                const bayiResponse = await makeAuthenticatedRequest('/auth/bayiler', 'GET');
                const bayiNames = bayiResponse.reduce((acc, bayi) => ({ ...acc, [bayi._id]: bayi.username }), {});
                setBayiNames(bayiNames);

                if (!response || !Array.isArray(response)) {
                    message.info('Hiç dekont bulunamadı.');
                } else {
                    const sortedPayments = response.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
                    setPayments(sortedPayments.map(item => ({
                        ...item,
                        bayiId: bayiNames[item.bayiId] || item.bayiId,
                    })));
                }
            } catch (error) {
                console.error('Dekontlar yüklenirken bir hata oluştu:', error);
                message.error('Dekontlar yüklenirken bir hata oluştu.');
            } finally {
                setLoading(false); // Veriler yüklendikten sonra loading durumunu kapat
            }
        };
        fetchReceipts();
    }, [makeAuthenticatedRequest]);

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const paginatedPayments = payments.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    return (
        <div className="table-wrapper">
            {loading ? (
                <div style={{ textAlign: 'center', padding: '50px 0' }}>
                    <Spin size="large" />
                </div>
            ) : (
                <>
                    <table className="responsive-table">
                        <thead>
                        <tr>
                            <th>Dekont Numarası</th>
                            <th>Ödeme ID</th>
                            <th>Bayi Adı</th>
                            <th>Miktar</th>
                            <th>Tahsilat Tutarı</th>
                            <th>Durum</th>
                            <th>İşlem Tarihi</th>
                            <th>Dekont İndir</th>
                        </tr>
                        </thead>
                        <tbody>
                        {paginatedPayments.map(payment => (
                            <tr key={payment._id}>
                                <td data-label="Dekont Numarası">{payment.dekontID}</td>
                                <td data-label="Ödeme ID">{payment.siparisID}</td>
                                <td data-label="Bayi Adı">{payment.bayiId}</td>
                                <td data-label="Miktar">{payment.odemeTutari} TL</td>
                                <td data-label="Tahsilat Tutarı">{payment.tahsilatTutari} TL</td>
                                <td data-label="Durum">
                                    {payment.sonuc === 'Başarılı' ?
                                        <CheckCircleOutlined style={{ color: 'green' }} /> :
                                        <CloseCircleOutlined style={{ color: 'red' }} />}
                                </td>
                                <td data-label="İşlem Tarihi">
                                    {moment(payment.islemTarihi).subtract(3, 'hours').format('YYYY-MM-DD HH:mm:ss')}
                                </td>
                                <td data-label="Dekont İndir">
                                    <Button type="primary" onClick={() => window.open(`https://sumerlibackend-ens1.onrender.com/api/param/download-receipt?dekont_id=${payment.dekontID}`)}>
                                        İndir
                                    </Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <Pagination
                        current={currentPage}
                        pageSize={pageSize}
                        total={payments.length}
                        onChange={handlePageChange}
                        showSizeChanger
                        pageSizeOptions={['20', '50', '100']}
                    />
                </>
            )}
        </div>
    );
};

export default PaymentsPage;
