import React from 'react';
import { Form, Input, Button, message } from 'antd';
import './LoginPage.css';
import logo from '../../assets/logo.png';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const LoginForm = () => {
    const navigate = useNavigate();
    const { login } = useAuth(); // useAuth hook'undan login fonksiyonunu kullanın

    const onFinish = async (values) => {
        try {
            const user = await login(values.email, values.password); // Kullanıcı email ile giriş yapacak
            if (user.userType === 'bayi') {
                navigate('/dashboard');
            } else if (user.userType === 'firma') {
                navigate('/firmadashboard');
            } else {
                navigate('/');
            }
        } catch (error) {
            console.error('Giriş hatası:', error);
            message.error('Kullanıcı adı veya şifre hatalı!');
        }
    };

    return (
        <div className="login-page-container">
            <div className="login-form-container">
                <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                    <img src={logo} alt="Logo" style={{ maxWidth: '300px', marginBottom: '20px' }} />
                </div>
                <Form
                    name="login"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'E-Posta giriniz!' }]}
                    >
                        <Input placeholder="E-Posta" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Şifre giriniz!' }]}
                    >
                        <Input.Password placeholder="Şifre" />
                    </Form.Item>
                    <br />
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block
                            style={{ backgroundColor: '#ed6924', borderColor: '#ffffff' }}>
                            Giriş Yap
                        </Button>
                    </Form.Item>
                    <p style={{ color: '#ffffff', textAlign: "center" }}>Giriş yapmak için lütfen sizinle paylaşılan bilgileri kullanınız. Bayi platformunu kullanmak için
                        kayıt olmak için <a href={'https://sümerlitel.com.tr/iletisim'}> tıklayın.</a>.</p>
                </Form>
            </div>
        </div>
    );
};

export default LoginForm;