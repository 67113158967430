import React, {useEffect, useState} from 'react';
import { Layout, Menu, Button, Drawer } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import {
    HomeOutlined,
    AppstoreOutlined,
    ShoppingCartOutlined,
    UserOutlined,
    MenuOutlined,
    LogoutOutlined,
    WalletOutlined
} from '@ant-design/icons';
import './Header.css';

const { Header} = Layout;

const DashboardHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, logout } = useAuth();
    const size = useWindowSize();
    const [drawerVisible, setDrawerVisible] = useState(false);

    // Ana menü öğelerini rol bazlı olarak tanımlayalım
    const getMobileMenuByRole = () => {
        if (user?.userType === 'firma') {
            return [
                { key: "firmadashboard", label: "Dashboard", icon: <HomeOutlined /> },
                { key: "paramlist", label: "İşlemler", icon: <WalletOutlined /> },
                { key: "tahsilatlar", label: "Tahsilatlar", icon: <WalletOutlined /> },
                { key: "bayiler", label: "Bayiler", icon: <UserOutlined /> }
            ];
        } else {
            return [
                { key: "dashboard", label: "Dashboard", icon: <HomeOutlined /> },
                { key: "odemelerim", label: "Ödemelerim", icon: <WalletOutlined /> },
                { key: "withcommission", label: "Komisyonlu", icon: <WalletOutlined /> },
                { key: "odeme-yap", label: "Ödeme Yap", icon: <WalletOutlined /> }
            ];
        }
    };

    const getAllMenuItems = () => {
        if (user?.userType === 'firma') {
            return [
                { key: "firmadashboard", label: "Dashboard", icon: <HomeOutlined /> },
                { key: "paramlist", label: "İşlemler", icon: <WalletOutlined /> },
                { key: "urunler", label: "Ürünler", icon: <AppstoreOutlined /> },
                { key: "siparisler", label: "Siparişler", icon: <ShoppingCartOutlined /> },
                { key: "tahsilatlar", label: "Tahsilatlar", icon: <WalletOutlined /> },
                { key: "bakiye", label: "Bakiye", icon: <WalletOutlined /> },
                { key: "bayiler", label: "Bayiler", icon: <UserOutlined /> }
            ];
        } else {
            return [
                { key: "dashboard", label: "Dashboard", icon: <HomeOutlined /> },
                { key: "urunlerim", label: "Ürünlerim", icon: <AppstoreOutlined /> },
                { key: "siparislerim", label: "Siparişlerim", icon: <ShoppingCartOutlined /> },
                { key: "odemelerim", label: "Ödemelerim", icon: <WalletOutlined /> },
                { key: "withcommission", label: "Komisyonlu Ödeme", icon: <WalletOutlined /> },
                { key: "odeme-yap", label: "Ödeme Yap", icon: <WalletOutlined /> }
            ];
        }
    };

    function useWindowSize() {
        const [windowSize, setWindowSize] = useState({
            width: undefined,
            height: undefined,
        });

        useEffect(() => {
            function handleResize() {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }

            window.addEventListener("resize", handleResize);
            handleResize();

            return () => window.removeEventListener("resize", handleResize);
        }, []);

        return windowSize;
    }

    const renderMenuItems = (items) => {
        return items.map(item => (
            <Menu.Item
                key={item.key}
                icon={item.icon}
                onClick={() => {
                    navigate(`/${item.key}`);
                    setDrawerVisible(false);
                }}
            >
                {item.label}
            </Menu.Item>
        ));
    };

    return (
        <Layout className="dashboard-layout">
            {size.width > 768 ? (
                <Header className="header">
                    <div className="logo-container" onClick={() => navigate('/')}>
                        <img src="/logo.png" alt="Logo" className="logo" />
                    </div>
                    <Menu
                        className="menu"
                        theme="dark"
                        mode="horizontal"
                        selectedKeys={[location.pathname.replace('/', '')]}
                    >
                        {renderMenuItems(getAllMenuItems())}
                    </Menu>
                    <Button className="logout-button" onClick={logout} icon={<LogoutOutlined />}>
                        Çıkış Yap
                    </Button>
                </Header>
            ) : (
                <>
                    <Header className="header-mobile">
                        <Button
                            className="menu-button"
                            icon={<MenuOutlined />}
                            onClick={() => setDrawerVisible(true)}
                        />
                        <div className="logo-container" onClick={() => navigate('/')}>
                            <img src="/logo.png" alt="Logo" className="logo" />
                        </div>
                        <Button className="logout-button" onClick={logout} icon={<LogoutOutlined />} />
                    </Header>
                    <Drawer
                        title="Menü"
                        placement="left"
                        closable={true}
                        onClose={() => setDrawerVisible(false)}
                        visible={drawerVisible}
                    >
                        <Menu mode="vertical" className="drawer-menu">
                            {renderMenuItems(getAllMenuItems())}
                        </Menu>
                    </Drawer>
                    <div className="mobile-footer">
                        <Menu
                            mode="horizontal"
                            className="bottom-nav"
                            selectedKeys={[location.pathname.replace('/', '')]}
                        >
                            {renderMenuItems(getMobileMenuByRole())}
                        </Menu>
                    </div>
                </>
            )}
        </Layout>
    );
};

export default DashboardHeader;