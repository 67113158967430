import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '../axios';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const verifyToken = async (token) => {
            try {
                const decodedToken = jwtDecode(token);
                const currentTime = Date.now() / 1000;

                if (decodedToken.exp < currentTime) {
                    localStorage.removeItem('token');
                    return false;
                }

                const response = await axios.get('/auth/validateToken', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setUser({
                    ...response.data.data,
                    token,
                });
                return true;
            } catch (error) {
                localStorage.removeItem('token');
                return false;
            }
        };

        const token = localStorage.getItem('token');
        if (token) {
            verifyToken(token).then(isValid => {
                if (!isValid) {
                    navigate('/login');
                }
            });
        } else {
            navigate('/login');
        }
    }, [navigate]);

    const login = async (email, password) => {
        try {
            const response = await axios.post('/auth/login', { email, password });
            const user = {
                ...response.data.data,
                token: response.data.token,
                userType: response.data.data.userType,
            };
            localStorage.setItem('token', response.data.token);
            setUser(user);
            return user;
        } catch (error) {
            throw error;
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        setUser(null);
        navigate('/login');
    };

    const makeAuthenticatedRequest = async (url, method, data) => {
        const token = localStorage.getItem('token');

        if (!token) {
            console.error('Hata: Yerel depolamada belirteç bulunamadı.');
            return;
        }

        try {
            const response = await axios({
                method,
                url,
                data,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error("Error making authenticated request:", error);

            if (error.response) {
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
                console.error('Response headers:', error.response.headers);
            } else if (error.request) {
                console.error('Request data:', error.request);
            } else {
                console.error('Error message:', error.message);
            }

            throw error;
        }
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, makeAuthenticatedRequest }}>
            {children}
        </AuthContext.Provider>
    );
};