import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, Row, Col, Table, message } from 'antd';
import { useAuth } from '../../context/AuthContext';

const PaymentComponent = () => {
    const [form] = Form.useForm();
    const { user, makeAuthenticatedRequest } = useAuth();
    const [installmentRates, setInstallmentRates] = useState([]);
    const [amount, setAmount] = useState(0);
    const [paymentStatus, setPaymentStatus] = useState(null);

    useEffect(() => {
        // Payment message listener
        const handleMessage = (event) => {
            // Allow messages only from trusted domains
            const allowedOrigins = [
                'https://pos-mp.param.com.tr',
                'https://sumerlibackend-ens1.onrender.com'
            ];

            if (!allowedOrigins.includes(event.origin)) {
                return;
            }

            try {
                const data = event.data;
                if (data.type === 'PAYMENT_COMPLETE') {
                    handlePaymentComplete(data.paymentData);
                }
            } catch (error) {
                console.error('Error processing payment message:', error);
            }
        };

        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, []);

    const handlePaymentComplete = async (paymentData) => {
        try {
            // Update UI with payment status
            setPaymentStatus(paymentData);

            // Show success message
            message.success('Ödeme işlemi tamamlandı!');

            // You can also trigger any additional frontend actions here
        } catch (error) {
            console.error('Error handling payment completion:', error);
            message.error('Bir hata oluştu.');
        }
    };

    const handleIframeLoad = () => {
        const iframe = document.getElementById('paymentIframe');
        try {
            const body = iframe.contentWindow.document.body;
            const html = iframe.contentWindow.document.documentElement;
            const height = Math.max(
                body.scrollHeight,
                body.offsetHeight,
                html.clientHeight,
                html.scrollHeight,
                html.offsetHeight
            );
            iframe.style.height = `${height}px`;
        } catch (error) {
            iframe.style.height = '800px';
        }
    };

const handleSubmit = async () => {
    try {
        const values = form.getFieldsValue();
        setAmount(values.amount);

        const response = await makeAuthenticatedRequest('/param/start', 'POST', {
            amount: values.amount,
            bayiId: user.id,
            returnUrl: `${window.location.origin}/payment-callback` // Frontend callback URL ekle
        });

        if (response && response.paymentUrl) {
            const iframe = document.getElementById('paymentIframe');
            iframe.onload = handleIframeLoad;

            // URL'yi modifiye et ve Accept header'ını ekle
            const modifiedUrl = new URL(response.paymentUrl);
            modifiedUrl.searchParams.append('accept', 'text/html');

            iframe.src = modifiedUrl.toString();

            // Taksit oranları ve diğer işlemler...
            const ratesResponse = await makeAuthenticatedRequest('/param/installment-rates', 'POST', {});
            if (ratesResponse) {
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(ratesResponse, 'text/xml');
                const banks = xmlDoc.getElementsByTagName('DT_Ozel_Oranlar');
                let formattedRates = Array.from(banks).map((bank, index) => {
                    const name = bank.getElementsByTagName('Kredi_Karti_Banka')[0].textContent;
                    if (name === 'ParamAcquirer') {
                        return null;
                    }
                    return {
                        key: index,
                        name: name,
                        image: bank.getElementsByTagName('Kredi_Karti_Banka_Gorsel')[0].textContent,
                        mo_01: bank.getElementsByTagName('MO_01')[0].textContent,
                        ...(name !== 'Param' && name !== 'Diğer Banka Kartları' ? {
                            mo_02: bank.getElementsByTagName('MO_02')[0].textContent,
                            mo_03: bank.getElementsByTagName('MO_03')[0].textContent,
                            mo_04: bank.getElementsByTagName('MO_04')[0].textContent,
                            mo_05: bank.getElementsByTagName('MO_05')[0].textContent,
                            mo_06: bank.getElementsByTagName('MO_06')[0].textContent,
                            mo_07: bank.getElementsByTagName('MO_07')[0].textContent,
                            mo_08: bank.getElementsByTagName('MO_08')[0].textContent,
                            mo_09: bank.getElementsByTagName('MO_09')[0].textContent,
                            mo_10: bank.getElementsByTagName('MO_10')[0].textContent,
                            mo_11: bank.getElementsByTagName('MO_11')[0].textContent,
                            mo_12: bank.getElementsByTagName('MO_12')[0].textContent
                        } : {})
                    };
                }).filter(Boolean);

                formattedRates = formattedRates.sort((a, b) => {
                    if (a.name === 'Param' || a.name === 'Diğer Banka Kartları') return 1;
                    if (b.name === 'Param' || b.name === 'Diğer Banka Kartları') return -1;
                    return 0;
                });

                setInstallmentRates(formattedRates);
            } else {
                console.error('Taksit oranları alınamadı:', ratesResponse.message);
            }
        }
    } catch (error) {
        console.error('Ödeme işlemi başlatılırken hata oluştu:', error);
        message.error('Ödeme başlatılamadı.');
    }
};    const columns = [
        {
            title: 'Taksit Sayısı',
            dataIndex: 'installment',
            key: 'installment',
        },
        {
            title: 'Komisyon Oranı',
            dataIndex: 'rate',
            key: 'rate',
        },
        {
            title: 'Komisyon Tutarı',
            dataIndex: 'commission',
            key: 'commission',
            render: text => <span style={{ color: 'red' }}>{text}</span>
        },
        {
            title: 'Toplam Tutar',
            dataIndex: 'total',
            key: 'total',
            render: text => <span style={{ color: 'red' }}>{text}</span>
        },
    ];

    return (
        <div>
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Form.Item
                    name="currency"
                    label="Para Birimi - Dolar seçtiğinizde de ödeme TL cinsinden yapılır. Kur bilgisi için bize ulaşın."
                    rules={[{ required: true, message: 'Lütfen para birimini seçin!' }]}
                >
                    <Select placeholder="Para birimini seçiniz">
                        <Select.Option value="tl">Türk Lirası</Select.Option>
                        <Select.Option value="usd">Dolar</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="amount"
                    label="Miktar"
                    rules={[{ required: true, message: 'Lütfen ödeme miktarını girin!' }]}
                >
                    <Input placeholder="Miktarı giriniz" type="number" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" block>
                        Ödeme Başlat
                    </Button>
                </Form.Item>
            </Form>

            <Row gutter={[16, 16]}>
                <Col xs={24} lg={12}>
                    <iframe
                        id="paymentIframe"
                        title="Payment"
                        style={{width: '100%', border: 'none', minHeight: '800px'}}
                        sandbox="allow-same-origin allow-scripts allow-forms allow-popups allow-top-navigation allow-top-navigation-by-user-activation allow-modals"
                        referrerPolicy="origin"
                        allow="payment"
                        loading="lazy"
                        importance="high"
                    />
                </Col>

                {/* Payment status display */}
                {paymentStatus && (
                    <Col xs={24}>
                        <Card
                            title="Ödeme Durumu"
                            className={`status-card ${paymentStatus.success ? 'success' : 'error'}`}
                        >
                        <p>Durum: <strong>{paymentStatus.success ? 'Başarılı' : 'Başarısız'}</strong></p>
                            <p>İşlem No: {paymentStatus.transactionId}</p>
                            <p>Dekont No: {paymentStatus.dekontId}</p>
                            <p>Tutar: {paymentStatus.amount} TL</p>
                            {paymentStatus.message && <p>Mesaj: {paymentStatus.message}</p>}
                            {paymentStatus.success && (
                                <Button
                                    type="primary"
                                    onClick={() => window.open(`/api/param/download-receipt?dekont_id=${paymentStatus.dekontId}`, '_blank')}
                                >
                                    Dekontu İndir
                                </Button>
                            )}
                        </Card>
                    </Col>
                )}

                {/* Existing installment rates display */}
                <Col xs={24} lg={12}>
                    {installmentRates.length > 0 && (
                        <Row gutter={[16, 16]}>
                            {installmentRates.map((rate, index) => {
                                const dataSource = [
                                    {
                                        key: '1',
                                        installment: '1 Taksit',
                                        rate: rate.mo_01,
                                        commission: `${(amount * rate.mo_01 / 100).toFixed(2)} TL`,
                                        total: `${(parseFloat(amount) + parseFloat((amount * rate.mo_01 / 100).toFixed(2))).toFixed(2)} TL`
                                    },
                                    ...(rate.mo_02 ? [{
                                        key: '2',
                                        installment: '2 Taksit',
                                        rate: rate.mo_02,
                                        commission: `${(amount * rate.mo_02 / 100).toFixed(2)} TL`,
                                        total: `${(parseFloat(amount) + parseFloat((amount * rate.mo_02 / 100).toFixed(2))).toFixed(2)} TL`
                                    }] : []),
                                    ...(rate.mo_03 ? [{
                                        key: '3',
                                        installment: '3 Taksit',
                                        rate: rate.mo_03,
                                        commission: `${(amount * rate.mo_03 / 100).toFixed(2)} TL`,
                                        total: `${(parseFloat(amount) + parseFloat((amount * rate.mo_03 / 100).toFixed(2))).toFixed(2)} TL`
                                    }] : []),
                                    ...(rate.mo_04 ? [{
                                        key: '4',
                                        installment: '4 Taksit',
                                        rate: rate.mo_04,
                                        commission: `${(amount * rate.mo_04 / 100).toFixed(2)} TL`,
                                        total: `${(parseFloat(amount) + parseFloat((amount * rate.mo_04 / 100).toFixed(2))).toFixed(2)} TL`
                                    }] : []),
                                    ...(rate.mo_05 ? [{
                                        key: '5',
                                        installment: '5 Taksit',
                                        rate: rate.mo_05,
                                        commission: `${(amount * rate.mo_05 / 100).toFixed(2)} TL`,
                                        total: `${(parseFloat(amount) + parseFloat((amount * rate.mo_05 / 100).toFixed(2))).toFixed(2)} TL`
                                    }] : []),
                                    ...(rate.mo_06 ? [{
                                        key: '6',
                                        installment: '6 Taksit',
                                        rate: rate.mo_06,
                                        commission: `${(amount * rate.mo_06 / 100).toFixed(2)} TL`,
                                        total: `${(parseFloat(amount) + parseFloat((amount * rate.mo_06 / 100).toFixed(2))).toFixed(2)} TL`
                                    }] : []),
                                    ...(rate.mo_07 ? [{
                                        key: '7',
                                        installment: '7 Taksit',
                                        rate: rate.mo_07,
                                        commission: `${(amount * rate.mo_07 / 100).toFixed(2)} TL`,
                                        total: `${(parseFloat(amount) + parseFloat((amount * rate.mo_07 / 100).toFixed(2))).toFixed(2)} TL`
                                    }] : []),
                                    ...(rate.mo_08 ? [{
                                        key: '8',
                                        installment: '8 Taksit',
                                        rate: rate.mo_08,
                                        commission: `${(amount * rate.mo_08 / 100).toFixed(2)} TL`,
                                        total: `${(parseFloat(amount) + parseFloat((amount * rate.mo_08 / 100).toFixed(2))).toFixed(2)} TL`
                                    }] : []),
                                    ...(rate.mo_09 ? [{
                                        key: '9',
                                        installment: '9 Taksit',
                                        rate: rate.mo_09,
                                        commission: `${(amount * rate.mo_09 / 100).toFixed(2)} TL`,
                                        total: `${(parseFloat(amount) + parseFloat((amount * rate.mo_09 / 100).toFixed(2))).toFixed(2)} TL`
                                    }] : []),
                                    ...(rate.mo_10 ? [{
                                        key: '10',
                                        installment: '10 Taksit',
                                        rate: rate.mo_10,
                                        commission: `${(amount * rate.mo_10 / 100).toFixed(2)} TL`,
                                        total: `${(parseFloat(amount) + parseFloat((amount * rate.mo_10 / 100).toFixed(2))).toFixed(2)} TL`
                                    }] : []),
                                    ...(rate.mo_11 ? [{
                                        key: '11',
                                        installment: '11 Taksit',
                                        rate: rate.mo_11,
                                        commission: `${(amount * rate.mo_11 / 100).toFixed(2)} TL`,
                                        total: `${(parseFloat(amount) + parseFloat((amount * rate.mo_11 / 100).toFixed(2))).toFixed(2)} TL`
                                    }] : []),
                                    ...(rate.mo_12 ? [{
                                        key: '12',
                                        installment: '12 Taksit',
                                        rate: rate.mo_12,
                                        commission: `${(amount * rate.mo_12 / 100).toFixed(2)} TL`,
                                        total: `${(parseFloat(amount) + parseFloat((amount * rate.mo_12 / 100).toFixed(2))).toFixed(2)} TL`
                                    }] : [])
                                ];

                                return (
                                    <Col xs={24} lg={12} key={index}>
                                        <Card title={rate.name} hoverable>
                                            <img src={rate.image} alt={rate.name} style={{
                                                width: '100%',
                                                maxHeight: 60,
                                                objectFit: 'contain',
                                                marginBottom: 10
                                            }} />
                                            <Table
                                                columns={columns}
                                                dataSource={dataSource}
                                                pagination={false}
                                            />
                                        </Card>
                                    </Col>
                                );
                            })}
                        </Row>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default PaymentComponent;