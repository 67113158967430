import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../context/AuthContext';
import { Card, Alert, Button, Spin, Typography, Space } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined, ReloadOutlined } from '@ant-design/icons';

const { Text } = Typography;

const PAYMENT_STATES = {
    WAITING: 'waiting',
    COMPLETED: 'completed',
    ERROR: 'error'
};

const WEBSOCKET_URL = 'wss://sumerlibackend-ens1.onrender.com/ws';
const POLLING_INTERVAL = 5000;
const TIMEOUT_DURATION = 180000; // 3 minutes

const PaymentResult = () => {
    const [paymentState, setPaymentState] = useState(PAYMENT_STATES.WAITING);
    const [receiptNumber, setReceiptNumber] = useState(null);
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [error, setError] = useState(null);
    const { makeAuthenticatedRequest } = useAuth();

    // Handle successful payment
    const handlePaymentSuccess = useCallback((data) => {
        setPaymentState(PAYMENT_STATES.COMPLETED);
        setReceiptNumber(data.dekontId);
        setPaymentDetails({
            amount: data.amount,
            date: new Date(data.date).toLocaleString('tr-TR'),
            installment: data.paymentDetails?.taksit,
            cardNumber: data.paymentDetails?.kartNo,
            orderId: data.paymentDetails?.siparisID
        });
    }, []);

    // Handle payment error
    const handlePaymentError = useCallback((message) => {
        setPaymentState(PAYMENT_STATES.ERROR);
        setError(message);
    }, []);

    // Setup WebSocket connection
    const setupWebSocket = useCallback((token) => {
        if (!token) return null;

        const ws = new WebSocket(`${WEBSOCKET_URL}?token=${token}`);

        ws.addEventListener('open', () => {
            console.log('WebSocket connection established');
            setError(null);
        });

        ws.addEventListener('message', (event) => {
            try {
                const data = JSON.parse(event.data);
                console.log('WebSocket message received:', data);

                if (data.type === 'PAYMENT_COMPLETE' && data.data.status === 'success') {
                    handlePaymentSuccess(data.data);
                    ws.close();
                } else if (data.type === 'PAYMENT_ERROR') {
                    handlePaymentError(data.error.message);
                    ws.close();
                }
            } catch (error) {
                console.error('Error processing WebSocket message:', error);
            }
        });

        ws.addEventListener('error', (error) => {
            console.error('WebSocket error:', error);
            setError('Connection error occurred. Checking payment status...');
        });

        return ws;
    }, [handlePaymentSuccess, handlePaymentError]);

    // Poll payment status
    const pollPaymentStatus = useCallback(async () => {
        try {
            const response = await makeAuthenticatedRequest('/param/odeme-kontrol', 'GET');
            if (response.durum === 'tamamlandi') {
                handlePaymentSuccess({
                    dekontId: response.dekontId,
                    amount: response.amount,
                    date: response.date,
                    paymentDetails: response.paymentDetails
                });
                return true;
            }
            return false;
        } catch (error) {
            console.error('Error checking payment status:', error);
            return false;
        }
    }, [makeAuthenticatedRequest, handlePaymentSuccess]);

    // Download receipt
    const downloadReceipt = useCallback(() => {
        if (!receiptNumber) return;

        try {
            window.location.href = `https://sumerlibackend-ens1.onrender.com/api/param/dekont-indir?dekont_id=${receiptNumber}`;
        } catch (error) {
            console.error('Error downloading receipt:', error);
            setError('Error downloading receipt');
        }
    }, [receiptNumber]);

    // Initialize WebSocket and polling
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            setError('Session information not found');
            return;
        }

        let ws = null;
        let pollingInterval = null;
        let timeoutId = null;

        if (paymentState === PAYMENT_STATES.WAITING) {
            // Setup WebSocket
            ws = setupWebSocket(token);

            // Setup polling
            pollingInterval = setInterval(async () => {
                const isComplete = await pollPaymentStatus();
                if (isComplete && ws) {
                    ws.close();
                }
            }, POLLING_INTERVAL);

            // Setup timeout
            timeoutId = setTimeout(() => {
                if (paymentState === PAYMENT_STATES.WAITING) {
                    handlePaymentError('Transaction timed out. Please try again later.');
                    if (ws) ws.close();
                }
            }, TIMEOUT_DURATION);
        }

        // Cleanup
        return () => {
            if (ws) ws.close();
            if (pollingInterval) clearInterval(pollingInterval);
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [paymentState, setupWebSocket, pollPaymentStatus, handlePaymentError]);

    // Loading state
    const LoadingState = () => (
        <Card title="Payment Processing" bordered>
            <Space direction="vertical" align="center" style={{ width: '100%' }}>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                <Text>Payment is being processed, please wait...</Text>
                {error && (
                    <Alert message={error} type="warning" showIcon />
                )}
            </Space>
        </Card>
    );

    // Success state
    const SuccessState = () => (
        <Card
            title={
                <Space>
                    <CheckCircleOutlined style={{ color: '#52c41a' }} />
                    Payment Successful!
                </Space>
            }
            bordered
        >
            <Alert
                message="Your payment has been processed successfully"
                description="You can now download your receipt"
                type="success"
                showIcon
                style={{ marginBottom: 16 }}
            />

            {paymentDetails && (
                <Space direction="vertical" style={{ width: '100%', marginBottom: 16 }}>
                    <Text>Amount: {paymentDetails.amount} TL</Text>
                    <Text>Date: {paymentDetails.date}</Text>
                    {paymentDetails.installment && <Text>Installment: {paymentDetails.installment}</Text>}
                    {paymentDetails.cardNumber && <Text>Card: **** **** **** {paymentDetails.cardNumber}</Text>}
                    {paymentDetails.orderId && <Text>Order ID: {paymentDetails.orderId}</Text>}
                </Space>
            )}

            <Button
                type="primary"
                onClick={downloadReceipt}
                block
                icon={<CheckCircleOutlined />}
            >
                Download Receipt
            </Button>
        </Card>
    );

    // Error state
    const ErrorState = () => (
        <Card
            title={
                <Space>
                    <CloseCircleOutlined style={{ color: '#ff4d4f' }} />
                    Payment Error
                </Space>
            }
            bordered
        >
            <Alert
                message="Payment failed"
                description={error || "An error occurred, please try again later"}
                type="error"
                showIcon
            />
            <Button
                type="primary"
                icon={<ReloadOutlined />}
                onClick={() => window.location.reload()}
                style={{ marginTop: 16 }}
                block
            >
                Try Again
            </Button>
        </Card>
    );

    // Render appropriate state
    const renderContent = () => {
        switch (paymentState) {
            case PAYMENT_STATES.COMPLETED:
                return <SuccessState />;
            case PAYMENT_STATES.ERROR:
                return <ErrorState />;
            default:
                return <LoadingState />;
        }
    };

    return (
        <div style={{ maxWidth: '400px', margin: '0 auto', padding: '20px' }}>
            {renderContent()}
        </div>
    );
};

export default PaymentResult;