import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from "./pages/Dashboard";
import UsersPage from "./pages/Users";
import LoginPage from './pages/auth/LoginPage';
import Products from "./pages/Products";
import Orders from "./pages/Orders";
import Payments from "./pages/Payments";
import Balances from "./pages/Balances";
import UsersRole from "./pages/UsersRole";
import MyPayments from "./pages/MyPayments";
import Success from "./pages/Success";
import FirmaDashboard from "./pages/FirmaDashboard";
import MyProducts from "./pages/MyProducts";
import MyOrders from "./pages/MyOrders";
import MyPaymentRecords from "./pages/MyPaymentsRecord";
import PaymentDone from "./pages/PaymentDone";
import PaymentFail from "./pages/PaymentFail";
import TransactionList from "./pages/TransactionList";
import PaymentWithCommision from "./pages/PaymentWithCommision";
import PaymentCallback from "./pages/PaymentCallback";

function App() {
    return (
        <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="/bayiler" element={<UsersPage />} />
            <Route path="/urunler" element={<Products />} />
            <Route path="/siparisler" element={<Orders />} />
            <Route path="/tahsilatlar" element={<Payments />} />
            <Route path="/odemelerim" element={<MyPaymentRecords />} />
            <Route path="/bakiye" element={<Balances />} />
            <Route path="/kullanicilar" element={<UsersRole />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/odeme-yap" element={<MyPayments />} />
            <Route path="/success" element={<Success />} />
            <Route path="/firmadashboard" element={<FirmaDashboard />} />
            <Route path="/urunlerim" element={<MyProducts />} />
            <Route path="/siparislerim" element={<MyOrders />} />
            <Route path="/payment-success" element={<PaymentDone />} />
            <Route path="/payment-failure" element={<PaymentFail />} />
            <Route path="/paramlist" element={<TransactionList />} />
            <Route path="/withcommission" element={<PaymentWithCommision />} />
            <Route path="/payment-callback" element={<PaymentCallback />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
    );
}

export default App;