import React, {useState, useEffect, useCallback} from 'react';
import {Form, Input, Button, Select, Card, Row, Col, Typography, Layout} from 'antd';
import {useAuth} from '../../context/AuthContext';

const {Title, Text} = Typography;
const {Content} = Layout;

function WithCommission() {
    const [form] = Form.useForm();
    const {user, makeAuthenticatedRequest} = useAuth();
    const [installmentRates, setInstallmentRates] = useState([]);
    const [calculatedAmount, setCalculatedAmount] = useState(0);
    const [selectedBank, setSelectedBank] = useState('');
    const [selectedInstallment, setSelectedInstallment] = useState('');

    const fetchInstallmentRates = useCallback(async () => {
        try {
            const ratesResponse = await makeAuthenticatedRequest('/param/installment-rates', 'POST', {});
            if (ratesResponse) {
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(ratesResponse, 'text/xml');
                const banks = xmlDoc.getElementsByTagName('DT_Ozel_Oranlar');
                const formattedRates = Array.from(banks).map((bank) => {
                    return {
                        name: bank.getElementsByTagName('Kredi_Karti_Banka')[0].textContent,
                        mo_01: bank.getElementsByTagName('MO_01')[0].textContent,
                        mo_02: bank.getElementsByTagName('MO_02')[0].textContent,
                        mo_03: bank.getElementsByTagName('MO_03')[0].textContent,
                        mo_04: bank.getElementsByTagName('MO_04')[0].textContent,
                        mo_05: bank.getElementsByTagName('MO_05')[0].textContent,
                        mo_06: bank.getElementsByTagName('MO_06')[0].textContent,
                        mo_07: bank.getElementsByTagName('MO_07')[0].textContent,
                        mo_08: bank.getElementsByTagName('MO_08')[0].textContent,
                        mo_09: bank.getElementsByTagName('MO_09')[0].textContent,
                        mo_10: bank.getElementsByTagName('MO_10')[0].textContent,
                        mo_11: bank.getElementsByTagName('MO_11')[0].textContent,
                        mo_12: bank.getElementsByTagName('MO_12')[0].textContent,
                    };
                });

                setInstallmentRates(formattedRates);
            } else {
                console.error('Taksit oranları alınamadı.');
            }
        } catch (error) {
            console.error('Taksit oranları yüklenirken hata oluştu:', error);
        }
    }, [makeAuthenticatedRequest]);

    useEffect(() => {
        fetchInstallmentRates();
    }, [fetchInstallmentRates]);

    const handleBankChange = (bank) => {
        setSelectedBank(bank);
    };

    const handleInstallmentChange = (installment) => {
        setSelectedInstallment(installment);
    };

    const handleCalculate = () => {
        const values = form.getFieldsValue();
        const {totalAmount} = values;

        const selectedRate = installmentRates.find(rate => rate.name === selectedBank);
        if (!selectedRate) {
            return;
        }

        const commissionRate = parseFloat(selectedRate[`mo_${selectedInstallment.padStart(2, '0')}`]);

        const calculatedBaseAmount = totalAmount / (1 + commissionRate / 100);
        setCalculatedAmount(calculatedBaseAmount.toFixed(2));
    };

    const handleSubmit = async () => {
        try {
            const formattedAmount = calculatedAmount.replace('.', ',');

            const response = await makeAuthenticatedRequest('/param/startcommission', 'POST', {
                amount: formattedAmount,
                bayiId: user.id
            });

            if (response && response.paymentUrl) {
                const iframe = document.getElementById('paymentIframe');
                iframe.src = response.paymentUrl;
            }
        } catch (error) {
            console.error('Ödeme işlemi başlatılırken hata oluştu:', error);
        }
    };

    return (
        <Layout style={{backgroundColor: '#f0f2f5', width: '100%'}}>
            <Content>
                <Card bordered={false} style={{
                    margin: '0 auto',
                    padding: '30px',
                    borderRadius: '10px',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                    maxWidth: '1200px'
                }}>
                    <Title level={2} style={{textAlign: 'center', marginBottom: '30px'}}>
                        <span lang="tr">Komisyon Dahil Ödeme</span>
                    </Title> <Form form={form} layout="vertical" onFinish={handleSubmit}>
                    <Row gutter={[24, 24]}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="currency"
                                label="Para Birimi"
                                rules={[{required: true, message: 'Lütfen para birimini seçin!'}]}
                            >
                                <Select placeholder="Para birimini seçiniz">
                                    <Select.Option value="tl">Türk Lirası</Select.Option>
                                    <Select.Option value="usd">Dolar</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="totalAmount"
                                label="Komisyon Dahil Toplam Ödeme Tutarı"
                                rules={[{required: true, message: 'Lütfen ödeme tutarını girin!'}]}
                            >
                                <Input placeholder="Toplam ödeme tutarı (TL)" type="number"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 24]}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="bank"
                                label="Banka Seçiniz"
                                rules={[{required: true, message: 'Lütfen banka seçin!'}]}
                            >
                                <Select placeholder="Banka seçiniz" onChange={handleBankChange}>
                                    {installmentRates.map((rate, index) => (
                                        <Select.Option key={index} value={rate.name}>{rate.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="installment"
                                label="Taksit Sayısı Seçiniz"
                                rules={[{required: true, message: 'Lütfen taksit sayısını seçin!'}]}
                            >
                                <Select placeholder="Taksit sayısını seçiniz" onChange={handleInstallmentChange}>
                                    {Array.from({length: 12}, (_, i) => i + 1).map(i => (
                                        <Select.Option key={i} value={i.toString()}>{`${i} Taksit`}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button type="primary" onClick={handleCalculate} block
                            style={{marginBottom: '20px', backgroundColor: '#fa8c16', borderColor: '#fa8c16'}}>
                        Komisyonsuz Tutarı Hesapla
                    </Button>
                    {calculatedAmount > 0 && (
                        <div style={{marginBottom: '20px', textAlign: 'center'}}>
                            <Title level={4}>Hesaplanan Komisyonsuz Tutar: {calculatedAmount} TL</Title>
                            <Text>Bakiye bu tutardan düşülecektir.</Text>
                            <Button type="primary" htmlType="submit" block
                                    style={{marginTop: '10px', backgroundColor: '#1890ff', borderColor: '#1890ff'}}>
                                Ödemeyi Başlat
                            </Button>
                        </div>
                    )}
                </Form>

                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <iframe id="paymentIframe" title="Payment" style={{
                                width: '100%',
                                border: 'none',
                                minHeight: '800px',
                                marginTop: '20px'
                            }}></iframe>
                        </Col>
                    </Row>
                </Card>
            </Content>
        </Layout>
    );
}

export default WithCommission;
